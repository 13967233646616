import React, { useState, useRef, useEffect } from "react";
import {
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Input,
  Button,
  InputGroup,
  InputRightAddon,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card.js";

import { ApiHeader } from '../../../../_helpers/ApiHeader';

import { GetUserData } from "../../../../_helpers/Auth";
import { BiWallet } from "react-icons/bi";
import { BiCheckCircle } from "react-icons/bi";
import { WarningIcon } from "@chakra-ui/icons";



function Balance(props) {
  const { contract, tokenbalances, isLoading, chain } = props;

  const [walletAddress, setWalletAddress] = useState('');


  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    address1: "",
    city: "",
    state: "",
    postalCode: "",
    emailAddress: "",
    mobilePhone: "",
    countryCode: "",
  });

  useEffect(()=>{
    const userdata = GetUserData();

    // console.log('start admin js')
    // console.log(userdata)

    setWalletAddress(userdata.address);


    const em = localStorage.getItem('winpangea_email');

    // console.log(em)
    
    setUserData((prev) => ({ ...prev, 'emailAddress': em }));

    // setUsername(user.length > 24 ? `${user.substr(0,6)}...${user.slice(-4)}` : user)

},[])

  const toast = useToast();

  // List of allowed countries with their three-letter codes
  const allowedCountries = [
    { name: "Spain", code: "ESP" },
    { name: "United States", code: "USA" },
    { name: "Germany", code: "DEU" },
    { name: "France", code: "FRA" },
    { name: "Japan", code: "JPN" },
  ];

  const balance = tokenbalances.find(token => token.token_address === contract);
  const formattedBalance = balance
    ? (balance.balance / Math.pow(10, balance.decimals)).toFixed(2)
    : null;

  const balanceBg = useColorModeValue("brand.900", "#1B254B");
  const maxValue = 1000;
  const minValue = 5;

  const [sliderValue, setSliderValue] = useState(minValue);
  const [inputValue, setInputValue] = useState(`${minValue}`);

  const fieldLabels = {
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    mobilePhone: "Mobile Phone",
    city: "City",
    state: "State",
    postalCode: "Postal Code",
    countryCode: "Country Code",
    address1: "Address",
  };



  const inputRef = useRef(null);
  const [modalStage, setModalStage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSliderChange = (value) => {
    setSliderValue(value);
    setInputValue(value.toString());
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) <= maxValue && Number(value) >= minValue)) {
      setInputValue(value);
    }
  };

  const handleInputBlur = () => {
    setSliderValue(Number(inputValue));
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setModalStage(1);
    setIsModalOpen(true);
  };

  const handleNextStage = () => {
    if (modalStage === 1) handleNextStageUSerData();
    else if (modalStage === 2) {
      setModalStage(3);
      buyCard();
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setModalStage(1);
  };

  const handleNextStageUSerData = () => {
    // Validation check for all fields in userData
    const isFormComplete = Object.keys(userData).every((key) => userData[key] !== "");

    if (!isFormComplete) {
      // Show error toast if any field is empty
      toast({
        title: "Error.",
        description: "Please fill in all fields.",
        status: "error",
      });
      return; // Stop proceeding to the next stage
    }

    // Proceed to the next stage if validation passes
    setModalStage(2);
  };

  const serviceChargeRate = 0.008;
  const displayBalance = parseFloat(formattedBalance) || 0.00;
  const amountBuy = Math.min(sliderValue, displayBalance / (1 + serviceChargeRate));
  const serviceCharge = (amountBuy * serviceChargeRate).toFixed(2);
  const totalAmount = (amountBuy * (1 + serviceChargeRate)).toFixed(2);

  const buyCard = () => {

    const participantId = `${userData.firstName}${userData.lastName}${walletAddress.slice(0, 8)}`.replace(/\s+/g, '').toLowerCase();

    const cardData = {
      chain: chain, // assuming chain is hardcoded here or could be passed as a prop
      participantId: participantId, // if this is generated or retrieved, replace accordingly
      firstName: userData.firstName,
      lastName: userData.lastName,
      emailAddress: userData.emailAddress,
      mobilePhone: userData.mobilePhone,
      language: "en-US", // hardcoded or passed as prop/state
      city: userData.city,
      state: userData.state,
      postalCode: userData.postalCode,
      countryCode: userData.countryCode,
      address1: userData.address1,
      amount: amountBuy.toFixed(2), // Using amount to purchase
    };
  
    fetch(`${process.env.REACT_APP_API_URL}pay/buy-card`, {
      method: 'POST',
      headers: ApiHeader('auth'),
      body: JSON.stringify(cardData)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      if (data.status === 'PENDING') {
        // Proceed to success stage or handle as needed
        setModalStage(4);
      } else {
        // Handle any errors returned by the API
        setModalStage(5);
      }
    })
    .catch(err => {
      console.log("fetch error:", err);
      toast({
        title: "Error.",
        description: "An error occurred while purchasing the card.",
        status: "error",
      });
    });
  };

  return (
    <>
      <Card direction="column" w="100%">
        <Flex
          justify="space-between"
          p="20px"
          mb="20px"
          borderRadius="16px"
          bgColor={balanceBg}
          bgPosition="right"
          bgSize="cover"
        >
          <Flex align="center" justify="center" w="100%">
            <Flex flexDirection="column" me="20px" w="100%">
              <Text color="white" fontSize="sm" fontWeight="500">
                Account Balance
              </Text>
              {isLoading && (
                <Flex w="100%" mt="20px" mb="20px" align="center" justify="center">
                  <Spinner color="white" thickness="4px" speed="0.65s" size="xl" />
                </Flex>
              )}
              {!isLoading && (
                <Text color="white" fontSize={{ sm: "18px", lg: "26px" }} fontWeight="700" lineHeight="100%">
                  {formattedBalance !== null ? `${formattedBalance} ${balance.symbol}` : "Balance not found"}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        {/* Slider and Input Section */}
        <Flex flexDirection="column" mt="20px" w="100%" px="20px">
          <Flex justifyContent="space-between" mb="10px">
            <Text fontSize="sm">{minValue} USDC</Text>
            <Text fontSize="sm">{maxValue} USDC</Text>
          </Flex>

          <Slider aria-label="slider-ex" min={minValue} max={maxValue} value={sliderValue} onChange={handleSliderChange} mb="50px">
            <SliderTrack bg="gray.200">
              <SliderFilledTrack bg="blue.500" />
            </SliderTrack>
            <SliderThumb boxSize={6} />
          </Slider>

          <InputGroup size="md" mb="40px">
            <Input
              ref={inputRef}
              variant="auth"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              type="number"
              max={maxValue}
              placeholder="Enter amount"
            />
            <InputRightAddon children="USDC" />
          </InputGroup>

          <Flex direction="row" mt="24px" mb="30px" ms="10px" alignItems="flex-start">
            <Button fontSize="sm" variant="brand" fontWeight="500" h="50" w="auto" minW="0" onClick={handleSubmit} isDisabled={totalAmount < 5.04}>
              <Icon as={BiWallet} w="20px" h="20px" me="10px" />
              Buy Prepaid Card
            </Button>
          </Flex>
        </Flex>
      </Card>

      {/* Modal Overlay */}
      <Modal isOpen={isModalOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalStage === 1 ? "Please provide your details" : modalStage === 2 ? "Confirm Purchase" : ""}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {modalStage === 1 && (
              <>
                {Object.keys(userData).map((field, idx) => (
                  <FormControl key={idx} mt={4}>
                    <FormLabel>{fieldLabels[field]}</FormLabel>
                    {field === "countryCode" ? (
                      <Select
                        placeholder="Select Country"
                        name="countryCode"
                        value={userData.countryCode}
                        onChange={(e) => setUserData((prev) => ({ ...prev, countryCode: e.target.value }))}
                      >
                        {allowedCountries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        variant="auth"
                        name={field}
                        placeholder={fieldLabels[field]}
                        value={userData[field]}
                        onChange={handleUserDataChange}
                        isReadOnly={field === "emailAddress"}
                      />
                    )}
                  </FormControl>
                ))}
              </>
            )}

            {modalStage === 2 && (
              <>
                <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">
                  Confirm Your Purchase
                </Text>

                <Flex direction="column" gap={3} mb={4}>
                  <Flex justifyContent="space-between">
                    <Text fontSize="md" fontWeight="500">Total Balance:</Text>
                    <Text fontSize="md" fontWeight="600">{displayBalance} USDC</Text>
                  </Flex>

                  <Flex justifyContent="space-between">
                    <Text fontSize="lg" fontWeight="bold">Amount to Purchase:</Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {`${amountBuy.toFixed(2)} USDC`}
                    </Text>
                  </Flex>

                  <Flex justifyContent="space-between">
                    <Text fontSize="md" fontWeight="500">Service Fee (0.08%):</Text>
                    <Text fontSize="md" fontWeight="600">{serviceCharge} USDC</Text>
                  </Flex>

                  <Flex justifyContent="space-between" borderTop="1px solid" borderColor="gray.200" pt={3}>
                    <Text fontSize="lg" fontWeight="600">Total Amount:</Text>
                    <Text fontSize="lg" fontWeight="600" >
                      {`${totalAmount} USDC`}
                    </Text>
                  </Flex>
                </Flex>
              </>
            )}

            {modalStage === 3 && (
              <Flex align="center" justify="center" direction="column" textAlign="center" p={6}>
                <Spinner color="blue.500" thickness="4px" speed="0.65s" size="xl" mb="60px" />
                <Text ml={4}>Purchasing your prepaid card.</Text>
                <Text ml={4}>This can take up to 5 minutes,</Text>
                <Text ml={4}>please don't close the page</Text>
              </Flex>
            )}

            {modalStage === 4 && (
              <Flex align="center" justify="center" direction="column" textAlign="center" p={6}>
                <Icon as={BiCheckCircle} color="green.500" boxSize={36} mb={4} />
                <Text fontSize="xxx-large" fontWeight="bold" mb={2} color="green.600">
                  Success!
                </Text>
                <Text fontSize="md" mb={4}>
                  Your prepaid card will be sent to your email:
                  <Text as="span" fontWeight="bold" color="blue.600">
                    {userData.emailAddress}
                  </Text>
                </Text>
                <Text fontSize="sm">
                  Please check your inbox for further details. If you do not receive the email shortly, check your spam folder or contact support.
                </Text>
              </Flex>
            )}

            {modalStage === 5 && (
              <Flex align="center" justify="center" direction="column" textAlign="center" p={6}>
                <Icon as={WarningIcon} color="red.500" boxSize={36} mb={4} />
                <Text fontSize="xxx-large" fontWeight="bold" mb={2} color="red.600">
                  Transaction Failed
                </Text>
                <Text fontSize="md" mb={4}>
                  Unfortunately, we couldn’t complete your purchase at this time.
                </Text>
                <Text fontSize="sm">
                  Please double-check your account details and try again, or contact support if the issue persists.
                </Text>
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            {modalStage < 3 && (
              <>
                <Button  fontSize="sm" variant="brand" fontWeight="500" h="50" w="auto" minW="0" mr={3} onClick={handleNextStage}>
                  {modalStage === 1 ? "Next" : "Confirm Purchase"}
                </Button>
                <Button fontSize="sm"
                  color="lightcoral"
                  fontWeight="500"
                  h="50"
                  ml="20px"
                  w="auto"
                  minW="0"
                  onClick={handleClose} disabled={modalStage > 2}>
                  Cancel
                </Button>
              </>
            )}
            {modalStage > 3 && (
                <Button  fontSize="sm" variant="brand" fontWeight="500" h="50" w="auto" minW="40" mr={3} onClick={handleClose}>
                  OK
                </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Balance;