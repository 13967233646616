import { Position, MarkerType } from '@xyflow/react';

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const { width: intersectionNodeWidth, height: intersectionNodeHeight } =
    intersectionNode.measured;
  const intersectionNodePosition = intersectionNode.internals.positionAbsolute;
  const targetPosition = targetNode.internals.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + targetNode.measured.width / 2;
  const y1 = targetPosition.y + targetNode.measured.height / 2;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.internals.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.measured.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.measured.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function createNodesAndEdges(responseData) {
    const nodes = [];
    const edges = [];
    const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  
    // Add the first node for the 'to' address
    const toAddress = responseData.to;
    const toLabel = `${toAddress.slice(0, 8)}...${toAddress.slice(-6)}`;
    let lowestScore = 100; // Start with the highest possible score
  
    const transfers = responseData.transfers;
    const totalTransfers = transfers.length;
  
    // Dynamically create nodes for 'from' addresses and connect them to the 'to' node
    for (let i = 0; i < totalTransfers; i++) {
      const fromAddress = transfers[i].from;
      const fromLabel = `${fromAddress.slice(0, 8)}...${fromAddress.slice(-6)}`;
      const score = transfers[i].score;
  
      // Update the lowest score for the target node
      if (score < lowestScore) {
        lowestScore = score;
      }
  
      // Determine the background color based on the score
      let backgroundColor = 'red';
      if (score > 80) {
        backgroundColor = 'lightgreen';
      } else if (score >= 30 && score <= 80) {
        backgroundColor = 'lightcoral'; // Light red
      }
  
      // Calculate node positions in a circular layout
      const degrees = i * (360 / totalTransfers);
      const radians = degrees * (Math.PI / 180);
      const x = 250 * Math.cos(radians) + center.x;
      const y = 250 * Math.sin(radians) + center.y;
  
      // Add nodes for 'from' addresses
      nodes.push({
        id: `${i}`,
        data: { label: fromLabel, score: score, address: fromAddress },
        position: { x, y },
        style: {
          backgroundColor: backgroundColor,
        },
      });
  
      // Create edges from 'from' addresses to 'to' address
      edges.push({
        id: `edge-${i}`,
        target: 'target',
        source: `${i}`,
        type: 'floating',
        markerEnd: {
          type: MarkerType.Arrow,
        },
      });
    }
  
    // Set the score of the target node to the lowest score from the source nodes
    nodes.push({
      id: 'target',
      type: 'walletNode',
      data: { label: toLabel, score: lowestScore },
      position: center,
    });
  
    return { nodes, edges };
  }
