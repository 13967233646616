/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Flex, Text, Card, VStack, useColorModeValue } from "@chakra-ui/react";
import { ApiHeader } from '../../../_helpers/ApiHeader';

import Balance from "../../../views/dashboard/components/balance/Balance";
import TransactionsTable from "../../../views/dashboard/portal/account/settings/components/TransactionsTable";

export default function Default() {
  const [walletAddress, setWalletAddress] = useState(""); // Manage wallet state
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");


  const [balance, setBalance] = useState('')
  const [wallet, setWallet] = useState('')
  const [tokenBalances, setTokenBalances] = useState([])
  const [transactions, setTransactions] = useState([])


  const [chain, setChain] = useState('sepolia');
  const [contract, setContract] = useState('0x94a9d9ac8a22534e3faca9f4e7f2e2cf85d5e4c8');

  const [isLoading, setIsLoading] = useState(true)


  const getTransactionsData = () => {

    setIsLoading(true)

    fetch(process.env.REACT_APP_API_URL+'user/transactions', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : chain
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {

      setIsLoading(false)
      console.log(data)

      if (data.status === 1) {
        setBalance(data.userData.balance);
        setTokenBalances(data.userData.balances);
        console.log(data.userData.transactions)
        setTransactions(data.userData.transactions);
        setWallet(data.userData.address); 
      } else {

        setBalance({balance: "0", symbol: ""});
        setTokenBalances([]);
        setTransactions([]);
      }
      
    })
    .catch(err => {
      setIsLoading(false)
      setBalance({balance: "0", symbol: ""});
      setTokenBalances([]);
      setTransactions([]);
      console.log("fetch error: " + err);
    });
  }

  const tableColumnsMostVisited = [
    {
      Header: "Address",
      accessor: "to_address",
    },
    {
      Header: "Type",
      accessor: "status",
    },
    {
      Header: "Date",
      accessor: "block_timestamp",
    },
    {
      Header: "Amount",
      accessor: "value",
    },
  ];


  useEffect(()=>{
    getTransactionsData();
  },[])


  return (
    <Flex direction="column" pt={{ base: "130px", md: "80px", xl: "80px" }} w="100%">
      {/* {walletAddress === "" && (
        
      )} */}
      <Flex direction={{ base: "column", xl: "row" }} pt="20px" w="100%">
        {/* Pass handleWalletSubmit to Information */}
        <Balance 
          chain={chain}
          tokenbalances={tokenBalances}
          contract={contract}
          isLoading={isLoading}
        />
        <Card ml={{ base: "0", xl: "20px" }} mb="20px" p="20px 20px 30px" borderRadius="20px" w={{ base: "auto", xl: "450px" }}>
          <VStack align='start' spacing={4}>
          <Text fontSize='2xl' fontWeight='bold' mb='20px'>
            Disclaimer:
          </Text>
          <Text mb='20px'>
            This product is in Beta. You need to be on the allow list to use it. Please contact us if you want to be a tester.
          </Text>
          </VStack>
        </Card>
      </Flex>
      
      <Flex direction={{ base: "column", xl: "row" }} pt="20px" w="100%">
        <TransactionsTable
          tableData={transactions}
          columnsData={tableColumnsMostVisited}
          isLoading={isLoading}
        />
      </Flex>
    </Flex>
  );
}
