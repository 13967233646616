// chakra imports
import {
  Box,
  Flex,
  Avatar,
  Text,
  DarkMode,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
//   Custom components
import SidebarCard from "../../../components/sidebar/components/SidebarCard";
import Brand from "../../../components/sidebar/components/Brand";
import Links from "../../../components/sidebar/components/Links";
import React from "react";
import avatar4 from "../../../assets/img/avatar-white.png";

// FUNCTIONS

function SidebarContent(props) {
  const { routes, username } = props;
  const textColor = useColorModeValue("navy.700", "white");

  // Define three-color gradient backgrounds
  const gradient = useColorModeValue(
    "linear-gradient(135deg, #6e407f, #ff6666, #f0936f, #6e407f)",
    "linear-gradient(135deg, #6e407f, #ff6666, #f0936f, #6e407f)"
  );
  return (
    <Flex
      direction='column'
      minH='100%'
      height='max-content'
      backgroundImage={gradient} // Change to gradient2, gradient3, gradient4, or gradient5 for different backgrounds
      pt='25px'
      borderRadius='20px'>
      <DarkMode>
        <Brand />
      </DarkMode>
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          {/* <Links routes={routes} /> */}
        </Box>
      </Stack>

      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        borderRadius='20px'>
        {/* <SidebarCard /> */}
      </Box>
      <Flex mt='75px' mb='56px' justifyContent='center' alignItems='center'>
        <Avatar h='140px' w='140px' src={avatar4} me='20px' />
      </Flex>
    </Flex>
  );
}

export default SidebarContent;