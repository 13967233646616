import React, { useEffect, useState, useCallback } from "react";
import axios from 'axios';
import {
  Flex,
  Text,
  Spinner,
  useColorModeValue,
  LightMode,
} from "@chakra-ui/react";
import {
  ReactFlow,
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import "../addressinfo/addressinfo.css";

import FloatingEdge from '../addressinfo/components/FloatingEdge';
import FloatingConnectionLine from '../addressinfo/components/FloatingConnectionLine';
import { createNodesAndEdges } from '../addressinfo/components/utils';
import WalletNode from '../addressinfo/components/CustomNode.js';
import Card from "../../../../components/card/Card.js";

const nodeTypes = { walletNode: WalletNode };
const edgeTypes = { floating: FloatingEdge };

export default function Information({ onWalletSubmit, wallet, ...rest }) {

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  // State for holding API response and generated nodes/edges
  const [apiResponse, setApiResponse] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(false); // Loading state for data fetching

  // Fetch wallet transaction data when wallet address changes and is not empty
  useEffect(() => {
    console.log(wallet)
    const fetchData = async () => {
      if (wallet && wallet.trim() !== "") {
        setLoading(true); // Start loading
        try {
          const response = await axios.post('https://api.palaidn.com/v1/data/check-wallet', { address: wallet });
          const { transfers } = response.data;
          
          // Check if transfers exist and are not empty
          if (transfers && transfers.length > 0) {
            const { nodes, edges } = createNodesAndEdges(response.data);
            setNodes(nodes);
            setEdges(edges);
            setApiResponse(response.data); // Set API response
          } else {
            setApiResponse(null); // No transfers found
          }
        } catch (error) {
          console.error("Error fetching wallet data:", error);
          setApiResponse(null); // Handle error by setting no response
        } finally {
          setLoading(false); // Stop loading
        }
      }
    };

    fetchData();
  }, [wallet]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: 'floating',
            markerEnd: { type: MarkerType.Arrow },
          },
          eds,
        ),
      ),
    [setEdges],
  );

  const handleNodeDoubleClick = (event, node) => {
    console.log('Double-clicked node label:', node.data.address);
    if (node.data.address) {
      console.log('sending:', node.data.address);
      onWalletSubmit(node.data.address);
    }
  };

  // Conditional rendering based on wallet state
  if (!wallet || wallet.trim() === "") {
    return (
      <Card mb='20px'>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            No wallet input
          </Text>
        </Flex>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card mb='20px'>
        <Flex direction='column' mb='30px' ms='10px' justifyContent='center' alignItems='center'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Fetching data for{' '}
            <Text as='span' color={textColorSecondary}>
              {wallet}
            </Text>
          </Text>
          <Spinner mt="30px" size='xl' color={textColorPrimary} />
        </Flex>
      </Card>
    );
  }

  if (apiResponse && apiResponse.transfers && apiResponse.transfers.length > 0) {
    return (
      <Card mb='20px'>
        <Flex direction='column' mb='30px' ms='10px'>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            Wallet transaction history for{' '}
            <Text as='span' color={textColorSecondary}>
              {wallet}
            </Text>
          </Text>
          <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
            You can double click on nodes to explore more.
          </Text>
          <LightMode>
            <ReactFlow
              color={textColorPrimary}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              onNodeDoubleClick={handleNodeDoubleClick} 
              fitView
              edgeTypes={edgeTypes}
              connectionLineComponent={FloatingConnectionLine}
            >
              <Background />
            </ReactFlow>
          </LightMode>
        </Flex>
      </Card>
    );
  }

  return (
    <Card mb='20px'>
      <Flex direction='column' mb='30px' ms='10px'>
        <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          No transaction history found for{' '}
          <Text as='span' color={textColorSecondary}>
            {wallet}
          </Text>
        </Text>
      </Flex>
    </Card>
  );
}