import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BiSolidUser } from 'react-icons/bi';

// Admin Imports
import Dashboard from './views/dashboard/main/main';
import Portal from './views/dashboard/portal/portal';
import AccountPage from './views/dashboard/account/main';

const routes = [
	{
		name: 'Portal',
		layout: '/portal',
		path: '/home',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		show:false,
		component: <Portal/>
	},
];

export default routes;
