import { Handle, Position } from '@xyflow/react';

function WalletNode({ data, isConnectable = true }) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div style={{
        width: '150px', 
        padding: '10px', 
        border: '1px solid black', 
        backgroundColor: 'white',
        textAlign: 'center'
      }}>
        <div style={{ fontSize: '12px' }}>{data.label}</div>
        <div style={{ fontWeight: 'bold', fontSize: '24px' }}>{data.score}</div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </>
  );
}

export default WalletNode;