import React, { useState } from "react";
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  Button,
  Icon,
  Heading,
  PinInput,
  PinInputField,
  useToast,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card.js";
import { useNavigate } from 'react-router';

import { ApiHeader } from '../../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../../_helpers/Auth';

import InputField from "../../../../components/fields/InputField";
import { BiWallet } from "react-icons/bi";

export default function Information({ onWalletSubmit, ...rest }) {

  const toast = useToast();
  const navigate = useNavigate()

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  const [stage, setStage] = useState(0);
  const [username, setUsername] = useState("");
  const [pin, setPin] = useState('');

  const [verificationToken, setVerificationToken] = useState('');

  const textColor = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the parent function to update the Addressinfo component
    if (username) {
      loginEmail();
    }
  };

  const loginEmail = () => {
    fetch(process.env.REACT_APP_API_URL+'auth/login-email', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        setVerificationToken(data.tokens)
        localStorage.setItem("winpangea_email", username);
        setStage(1);
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const handleComplete = (value) => {
    setPin(value)
  };


  const verifyPasscode = (event) => {
    event.preventDefault();
    if (pin === '') {
      toast({
        title: 'Error.',
        description: "Please enter pin",
        status: 'error',
      })
      return;
    }
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'auth/login-code', {
      method: 'POST',
      body: JSON.stringify({
        "code" : pin,
        "token" : verificationToken
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        SetJwtAuth(data.tokens.access.token);
        setStage(0);
        if (data.newUser) {
          console.log('new user, need to set pin');
        } else {
          console.log('logged in')
          navigate('/portal/home')
        }
      } else {
        console.log(data.message)
        setStage(0);
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        setStage(0);
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }


  const loginVerification = () => {
    return (
      <Card mb="20px" {...rest}>
        <Heading
            color={textColor}
            fontSize='36px'
            mb='16px'
            mx={{ base: "auto", lg: "unset" }}
            textAlign="center">
            Verification
        </Heading>
        <Text
            color='gray.400'
            fontSize='md'
            maxW={{ base: "95%", md: "100%" }}
            mx={{ base: "auto", lg: "unset" }}
            textAlign="center">
            Enter the code we send to your email to unlock your account.
        </Text>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "395px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "auto" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <Flex justify='center'>
              <PinInput mx='auto' onComplete={handleComplete}>
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                />
              </PinInput>
            </Flex>

            <Button
              fontSize='14px'
              variant='brand'
              borderRadius='16px'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              isLoading={isLoading}
              onClick={verifyPasscode}
              mt='12px'>
              Login
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text
              color={textColorDetails}
              fontWeight='400'
              fontSize='14px'
              mx={{ base: "auto", lg: "unset" }}
              textAlign="center">
              Haven't received it?<br></br>
              We've send the code to <span style={{color: textColorBrand}}>{username}</span>.<br></br>
              Please check your spam folder if it's not in your inbox.<br></br>
              If you do not find it in spam folder, please check the spelling of your email address.<br></br>
            </Text>
          </Flex>
        </Flex>
      </Card>
    )
  }


  return (
    <FormControl>
      {stage===0 && (
        <Card mb="20px" {...rest}>
          <Flex direction="column" mb="30px" ms="10px">
            <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
              Login / Check balance
            </Text>
            <Text fontSize="md" color={textColorSecondary}>
              Input your email and check the balance of your wallet.
            </Text>
          </Flex>
          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <InputField
              mb="0px"
              me="30px"
              id="wallet"
              label="Email"
              placeholder="email address"
              onChange={(e) => setUsername(e.target.value)} // Update wallet input value
              value={username}
            />
          </SimpleGrid>
          <Flex direction="row" mt="24px" mb="30px" ms="10px" alignItems="flex-start">
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              h="50"
              w="auto"
              minW="0"
              onClick={handleSubmit}
            >
              <Icon as={BiWallet} w="20px" h="20px" me="10px" />
              Proceed
            </Button>
          </Flex>
        </Card>
      )}
      {stage === 1 && loginVerification()}
      
    </FormControl>
  );
}